<template>
  <div>
    <vue-type
      title="Tipo de grado académico"
      permission-type="tipogradoacademico"
      url-get-register="/v1/grado-academico/get/"
      url-delete-register="/v1/grado-academico/get/delete"
      url-active-register="/v1/grado-academico/update/active/"
      url-delete-register-one="/v1/grado-academico/delete/"
      url-delete-all="/v1/grado-academico/delete/"
      url-delete-register-back="/v1/grado-academico/delete/restore/"
      url-delete-all-back="/v1/grado-academico/delete/restoreall"
      url-update-one="/v1/grado-academico/update/"
      url-create-one="/v1/grado-academico/create"
    />
  </div>
</template>
<script>
import TypeOpcion from '@/components/optionsCrud/typeIndex.vue'

export default {
  components: {
    'vue-type': TypeOpcion,
  },
}
</script>
